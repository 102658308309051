#app-bar {
  background-color:transparent;
  box-shadow: none;
  
}

#nav-box-title {
  display: flex;
  float:left;
}
#nav-box-page {
  display: flex;
  float:right;
  
}

#nav-button {
  color:black;
}